<template>
    <div>
        <admin-template>
            <h2 class="text-2xl font-bold text-slate-500 mb-4">Mes annonces</h2>
            <div>
                <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-2">
                        <div class="grid grid-cols-3 gap-4">
                            <div @click="$router.push({name: 'admin.annonce.add'})" class="h-[200px] bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded-xl shadow border flex items-center justify-center">
                                <div class="text-slate-400 text-center">
                                    <i class="fas fa-plus text-3xl"></i>
                                    <div>Déposer une annonce</div>
                                </div>
                            </div>
                            <div @click="$router.push({name: 'admin.annonce.edit', params: {ida: annonce.ida}})" v-for="(annonce, annonceindex) in annonces" :key="'annonce-' + annonceindex" class="rounded-xl border w-full h-[200px] relative cursor-pointer border-2 border-slate-100 hover:border-slate-500 duration-200" :style="'background-image: url(' + (annonce.img ? annonce.img : 'https://atlas-content-cdn.pixelsquid.com/stock-images/cartoon-house-Xlew7P7-600.jpg') + ');'" style="background-size: cover; background-position: center center;">
                                <div class="bg-white/98 shadow shadow-slate-500 absolute left-4 right-4 bottom-4 bg-white p-4 rounded-xl">
                                    <div class="flex items-center justify-between gap-2">
                                        <div class="font-light text-xs">
                                            <div>{{ annonce.adresse + ' ' + annonce.cp + ' ' + annonce.ville }}</div>
                                        </div>
                                        <div>
                                            <div class="text-[#28b67e] font-black">{{annonce.prix}}€</div>
                                            <div class="text-[#28b67e] font-light text-xs -mt-0.5">
                                                <template v-if="annonce.type == 0">Location</template>
                                                <template v-if="annonce.type == 1">Vente</template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="rounded-xl p-4 border mt-4 bg-white shadow shadow-slate-200"></div>
                        <div class="rounded-xl p-4 border mt-4 bg-white shadow shadow-slate-200"></div>
                        <div class="rounded-xl p-4 border mt-4 bg-white shadow shadow-slate-200"></div> -->
                    </div>
                    <div>
                        <div class="bg-white p-4 rounded-xl shadow border sticky top-2">
                            <h2 class="text-2xl font-bold border-b-2 pb-4">Filtres</h2>
                            <div class="mt-2">
                                <h4 class="font-bold text-sm text-slate-600 mb-2">Annonce</h4>
                                <div>
                                    <input type="text" placeholder="Référence de l'annonce" class="font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                                </div>
                            </div>
                            <div class="mt-2">
                                <h4 class="font-bold text-sm text-slate-600 mb-2">Type de vente</h4>
                                <div>
                                    <div><input id="location" type="radio" /> <label class="ml-2 font-light text-sm" for="location">Location</label></div>
                                    <div><input id="vente" type="radio" /> <label class="ml-2 font-light text-sm" for="vente">Vente immobilière</label></div>
                                </div>
                            </div>
                            <div class="mt-2">
                                <h4 class="font-bold text-sm text-slate-600 mb-2">Secteurs</h4>
                                <div>
                                    <div><input id="bourg" type="radio" /> <label class="ml-2 font-light text-sm" for="bourg">Bourg en bresse</label></div>
                                    <div><input id="saint-denis" type="radio" /> <label class="ml-2 font-light text-sm" for="saint-denis">Saint Denis les bourg</label></div>
                                    <div><input id="aa" type="radio" /> <label class="ml-2 font-light text-sm" for="aa">Pont d'ain</label></div>
                                    <div><input id="bb" type="radio" /> <label class="ml-2 font-light text-sm" for="bb">Ambérieu en Bugey</label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            annonces: []
        }
    },
    methods: {
        async getAnnonces() {
            let response = await this.$request.post('/annonce/list')
            this.annonces = response.data.api
        }
    },
    mounted () {
        this.getAnnonces()
    },
}
</script>

<style lang="scss" scoped>

</style>
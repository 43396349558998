import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminHome from '../views/admin/AdminHome.vue'
import AdminAnnonces from '../views/admin/AdminAnnonces.vue'
import AdminEditAnnonce from '../views/admin/EditAnnonce.vue'
import AdminAddAnnonce from '../views/admin/AddAnnonce.vue'
import AdminAgence from '../views/admin/Agence.vue'
import AdminAgenceCreate from '../views/admin/AdminAgenceCreate.vue'
import Pic from '../views/Pic.vue'

import LandingPage from '../views/LandingPage.vue'
import Register from '../views/auth/Register.vue'
import Login from '../views/auth/Login.vue'
import ValidAccount from '../views/auth/ValidAccount.vue'
import Logout  from '../views/auth/Logout.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'landing', component: LandingPage},
  {path: '/login', name: 'login', component: Login},
  {path: '/register', name: 'register', component: Register},
  {path: '/valid-account/:token', name: 'valid-account', component: ValidAccount},
  {path: '/admin/logout', name: 'logout', component: Logout},

  {path: '/admin', name: 'admin.home', component: AdminHome},
  {path: '/admin/annonces', name: 'admin.annonce.list', component: AdminAnnonces},
  {path: '/admin/annonce/add', name: 'admin.annonce.add', component: AdminAddAnnonce},
  {path: '/admin/annonce/edit/:ida', name: 'admin.annonce.edit', component: AdminEditAnnonce},
  {path: '/admin/agence', name: 'admin.agence', component: AdminAgence},
  {path: '/admin/agence/create', name: 'admin.agence.create', component: AdminAgenceCreate},
  {path: '/pic', name: 'pic', component: Pic},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

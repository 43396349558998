<template>
    <div>

    </div>
</template>

<script>
export default {
    async mounted () {
        let response = await this.$request.post('/account/valid', {
            token: this.$route.params.token
        })
        if (response.data.api) {
            localStorage.setItem('auth_token', response.data.api)
            window.location = '/admin/agence/create'
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
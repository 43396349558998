<template>
    <div>
        <admin-template>
        </admin-template>
    </div>
</template>

<script>
export default {
    props: {
        account: {},
    },
}
</script>

<style lang="scss" scoped>

</style>
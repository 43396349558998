<template>
    <div class="bg-white">
        <div class="h-screen flex items-center justify-center">
            <div class="min-w-[400px]">
                <h2 class="text-3xl font-bold">Bienvenue {{ $account.prenom }} ! 👋</h2>
                <h4 class="mt-2 font-light">Il ne vous reste plus qu'à enregistrer votre agence</h4>
                <div class="mt-10">
                    <div class="mb-4" id="inp-nom">
                        <label class="mb-1">Nom de l'agence</label>
                        <input v-model="form.nom" type="text" class="p-1.5 border rounded w-full rounded focus:outline-none">
                    </div>
                    <div class="mb-4" id="inp-tel">
                        <label class="mb-1">Téléphone de contact</label>
                        <input v-model="form.tel" type="text" class="p-1.5 border rounded w-full rounded focus:outline-none">
                    </div>
                    <div class="mb-4"  id="inp-siret">
                        <label class="mb-1">Numéro de siret</label>
                        <input v-model="form.siret" type="text" class="p-1.5 border rounded w-full rounded focus:outline-none">
                    </div>
                    <div class="mb-4" id="inp-address">
                        <label class="mb-1">Adresse</label>
                        <!-- <input v-model="adresseInp" autocomplete="street-address" type="text" class="p-1.5 border rounded w-full rounded focus:outline-none"> -->
                        <address-input v-model="form.address" />
                    </div>
                    <div class="text-center mt-8">
                        <div>
                            <button @click="sendForm" class="bg-[#28b67e] shadow-lg shadow-slate-300 text-white rounded-xl font-bold px-6 py-3">Terminer l'inscription <i class="fas fa-circle-check ml-1"></i></button>
                        </div>
                        <div class="mt-6 font-light text-xs">
                            <button @click="$router.push({name: 'logout'})" class="hover:underline"><i class="fas fa-power-off mr-1"></i> Se déconnecter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {},
            adresseInp: null,
        }
    },
    methods: {
        async sendForm() {
            if (!this.$validation.check([
                {value: this.form.nom, rules: 'required', id: 'inp-nom'},
                {value: this.form.tel, rules: 'required|tel', id: 'inp-tel'},
                {value: this.form.siret, rules: 'required|siret', id: 'inp-siret'},
                {value: this.form.address, rules: 'required', id: 'inp-address'},
            ])) {
                return
            }

            let response = await this.$request.post('/agence/edit-add', this.form)
            if (response.data.api?.id) {
                window.location = '/admin'
            }
            
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false


// Package
import VuePannellum from 'vue-pannellum'
Vue.component('VPannellum', VuePannellum)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  timeout: 2500,
  hideProgressBar: true,
});

import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

// Components
import AdminTemplate from './views/admin/components/AdminTemplate.vue'
Vue.component('admin-template', AdminTemplate)

import AddressInput from './components/AddressInput.vue'
Vue.component('address-input', AddressInput)



// Global
import apiService from './services/api.service'
Vue.prototype.$request = apiService

import EventHub from '@/eventhub';
Vue.prototype.$eventHub = EventHub

import ValidationTool from './tools/ValidationTool'
Vue.prototype.$validation = ValidationTool



// Init
var account = null

let initVue = () => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}

// Get account
if (localStorage.getItem('auth_token')) {
  (async () => {
      let response = await apiService.post('/account/get')
      account = response.data.api;
      Vue.prototype.$account = account

      // EventHub.$on('get-account', (callback) => {
      //   callback(account)
      // })
      
      initVue()
  })();
} else {
  initVue()
}


<template>
    <div>
        <div class="p-3">
            <div class="flex items-center justify-between">
                <div>
                    <div class="font-light text-sm text-slate-600">Bienvenue,</div>
                    <div class="text-xl font-bold"><i class="fas fa-user text-primary mr-1.5 text-sm"></i> Victor Anweiler</div>
                </div>
                <button class="border rounded-full px-4 py-2.5 bg-white"><i class="fas fa-bars"></i></button>
            </div>
            <div class="flex items-center gap-8 py-7">
                <div @click="$router.push({name: 'admin.home'})" class="text-center">
                    <button class="border rounded-full px-[14px] py-2.5 bg-white"><i class="fas fa-home text-slate-400"></i></button>
                    <div class="text-xs font-light mt-1">Accueil</div>
                </div>
                <div class="text-center">
                    <button class="border rounded-full px-[18px] py-2.5 bg-white"><i class="fas fa-building text-primary text-lg"></i></button>
                    <div class="text-xs font-light mt-1">Agence</div>
                </div>
                <div class="text-center">
                    <button class="border rounded-full px-4 py-2.5 bg-white"><i class="fas fa-user text-slate-400"></i></button>
                    <div class="text-xs font-light mt-1">Mon compte</div>
                </div>
            </div>

            <div class="bg-white p-4 rounded shadow" v-if="agence">
                <div class="mb-4">
                    <div class="text-sm mb-1">Nom</div>
                    <input v-model="agence.nom" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                </div>
                <div class="mb-4">
                    <div class="text-sm mb-1">Siret</div>
                    <input v-model="agence.siret" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                </div>
                <div class="mb-4">
                    <div class="text-sm mb-1">Adresse</div>
                    <input v-model="agence.adresse" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                </div>
                <div class="mb-4">
                    <div class="text-sm mb-1">Code postal</div>
                    <input v-model="agence.cp" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                </div>
                <div class="mb-4">
                    <div class="text-sm mb-1">Ville</div>
                    <input v-model="agence.ville" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                </div>
                <div class="mb-4">
                    <div class="text-sm mb-1">Téléphone</div>
                    <input v-model="agence.tel" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                </div>
                <button @click="saveAgence" class="px-4 py-2 rounded text-sm font-bold bg-primary text-white"><i class="fas fa-check mr-1"></i> Enregistrer</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            agence: null
        }
    },
    methods: {
        async getAgence() {
            let response = await this.$request.post('/agence/get')
            this.agence = response.data.api
        },

        async saveAgence() {
            let response = await this.$request.post('/agence/edit', this.agence)
            console.log(response.data.api);
        }
    },
    mounted () {
        this.getAgence()
    },
}
</script>

<style lang="scss" scoped>

</style>
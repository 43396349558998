<template>
    <div>
        <!-- <div class="flex items-center gap-2 py-2">
            <div>
                <select @input="startCam" v-model="selectedCam">
                    <template v-for="(cam, camIndex) in cams">
                        <option :key="'cam-' + camIndex" :value="cam.deviceId">{{cam.kind}}</option>
                    </template>
                </select>
            </div>
        </div> -->
        <div class="flex items-center w-full overflow-x-auto">
            <img v-for="(photo, photoIndex) in photos" :key="'photo-' + photoIndex" :src="photo" alt="Captured photo" class="-mr-[25%]" />
            <video id="video" class="w-[100vw] opacity-70"></video>
            <!-- <canvas id="canvas" width="300" height="400"></canvas> -->
        </div>
        <canvas id="canvas" style="display:none;"></canvas>
        <div class="mt-4 text-center">
            <button @click="capturePhoto" class="px-4 py-2 bg-green-600 text-xl text-white rounded">Capture</button>
        </div>
    </div>
</template>

<script>
import { Camera } from "./../libs/camera";
import axios from 'axios'

export default {
    data() {
        return {
            camera: null,
            cams: [],
            selectedCam: null,
            camWidth: null,
            camHeight: null,

            photos: [],
        }
    },
    methods: {
        async startCam() {
            // const canvas = document.getElementById("canvas");
            // const context = canvas.getContext("2d");

            // try {
            //     // Obtenir l'accès à la webcam
            //     const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            //     this.cameraStream = stream;

            //     // Créer un élément vidéo invisible pour capturer les frames
            //     this.video = document.createElement('video');
            //     this.video.srcObject = stream;
            //     this.video.play();

            //     // Dessiner le flux vidéo dans le canvas à chaque frame
            //     this.intervalId = setInterval(() => {
            //     context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
            //     }, 1000 / 30); // 30 FPS
            // } catch (error) {
            //     console.error("Erreur d'accès à la caméra : ", error);
            // }

            this.camera = null
            let video = document.getElementById('video')
            
            this.camera = new Camera(video, {
                onFrame: async () => {
                // use video element as image
                },
                video: {
                    deviceId: this.selectedCam?.deviceId
                },
                backcamera: 1,
                fps: 1,
            });
            await this.camera.start();

            // navigator.mediaDevices.enumerateDevices().then((devices) => {
            //     this.cams = devices
            // });
            // const stream = await navigator.mediaDevices.getUserMedia({video: true});
            // const camSettings = stream.getVideoTracks()[0].getSettings();
            
            // this.camWidth = camSettings.width
            this.camHeight = video.getBoundingClientRect().height
            
            setTimeout(() => {
                console.log(this.camera.videoElement.videoHeight);
                let video2 = document.getElementById('video')
                // console.log(video2);
                // console.log(video2.videoHeight);
            }, 1000)
        },

        async capturePhoto() {
            const video = document.getElementById("video");
            const canvas = document.getElementById("canvas");
            const context = canvas.getContext("2d");

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Dessiner l'image de la vidéo sur le canvas
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const img = canvas.toDataURL("image/png")            

            let response = await this.$request.post('/pic/upload', {
                img: img
            })
            console.log(response.data);

            // Convertir le canvas en une URL d'image (base64)
            this.photos.push(img);
        }
    },
    mounted () {
        this.startCam()
    },    
}
</script>

<style lang="scss" scoped>

</style>
import axios from 'axios'

var apiKey = "pk.eyJ1IjoidmljdG9yYW53ZGV2IiwiYSI6ImNtMWZpaXJyNzA0a2IycXNhc2NoaGg3eHgifQ.zSimlKcC1HqpQS9pJ8LV8g"
const MapService = {
    apiKey,
    search: async (address) => {
        // let countries = 'AT,BE,BG,HR,CY,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE'

        let result = []
        let addressEncoded = encodeURI(address)
        await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + addressEncoded + '.json?access_token=' + apiKey + '&country=fr')
        .then((response) => {
            result = response.data.features
        })
        return result
    }
}
export default MapService

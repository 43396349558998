<template>
    <div class="bg-white">
        <!-- Header -->
        <div class="bg-white/50 backdrop-blur fixed top-0 left-0 right-[30%] py-3 z-20">
            <div class="container mx-auto flex items-center justify-between px-10">
                <img src="https://i.ibb.co/KVpfg3s/Capture-d-e-cran-2024-09-12-a-18-48-33-removebg-preview.png" class="h-[25px]" alt="">
                <div class="flex items-center gap-4">
                    <div @click="$router.push({name: 'landing'})" class="font-light cursor-pointer">Accueil</div>
                    <div class="font-light cursor-pointer">Tarifs</div>
                    <div class="font-light cursor-pointer">Contact</div>
                    <button @click="$router.push({name: 'register'})" class="ml-4 bg-black text-white text-sm rounded-xl px-4 py-3 cursor-pointer">Créer mon compte</button>
                </div>
            </div>
        </div>

        <!-- Register -->
        <div class="fixed top-0 left-0 bottom-0 right-[30%] px-10 bg-white flex items-center">
            <div class="container mx-auto max-w-[500px]">
                <h1 class="text-4xl font-bold text-center">Connectez-vous pour accéder à votre espace</h1>
                <div class="mt-10">
                    <template>
                        <div class="mb-4" id="inp-email">
                            <label class="mb-1">Adresse email</label>
                            <input v-model="form.email" type="email" class="p-1.5 border rounded w-full rounded focus:outline-none">
                        </div>
                        <div class="mb-4" id="inp-password">
                            <label class="mb-1">Mot de passe</label>
                            <input v-model="form.password" type="password" class="p-1.5 border rounded w-full rounded focus:outline-none">
                        </div>
                        <div class="text-center mt-8">
                            <button @click="sendForm" class="bg-[#28b67e] shadow-lg shadow-slate-300 text-white rounded-xl font-bold px-6 py-3"><i class="fas fa-check mr-1"></i> Connexion</button>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="fixed top-0 bottom-0 w-[30%] right-0" style="background-image: url('https://img.freepik.com/free-vector/realistic-3d-shapes-floating-background_52683-60316.jpg?t=st=1726869420~exp=1726873020~hmac=8b448f0ccd1f4fbc98451c98e6e6bdd781ce1e7edba41df1c97528598073a1fc&w=1060'); background-size: cover; background-position: center center;"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {},
        }
    },
    methods: {
        async sendForm() {
            if (!this.$validation.check([
                {value: this.form.email, rules: 'required', id: 'inp-email'},
                {value: this.form.password, rules: 'required', id: 'inp-password'},
            ])) {
                return
            }

            let response = await this.$request.post('/account/login', this.form)
            if (response.data.api) {
                this.form = {}
                localStorage.setItem('auth_token', response.data.api)
                window.location = '/admin'
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
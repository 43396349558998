<template>
    <div>
        <modal name="modal-add-image" height="auto" :scrollable="true">
            <div class="p-5" style="z-index: 999 !important;">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-lg">
                    <h2 class="font-bold">
                        <i class="fas fa-plus mr-1"></i> Ajouter une pièce
                    </h2>
                    <button @click="$modal.hide('modal-add-image')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="bg-slate-100 rounded p-4">
                    <div>
                        <div class="mb-4">
                            <div class="text-sm font-bold mb-0.5">Nom de la pièce</div>
                            <input v-model="newPieceName" type="text" class="bg-white font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                        </div>
                        <div class="mb-4">
                            <div class="text-sm font-bold mb-0.5">Vidéo du lieu à convertir</div>
                            <input @change="uploadVideo" type="file" class="bg-white font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                        </div>
                        <button @click="sendFormUploadVideo" class="text-xs bg-[#28b67e] text-white rounded px-4 py-2.5"><i class="fas fa-check mr-1"></i> Convertir la vidéo</button>
                    </div>
                </div>
            </div>
        </modal>

        <admin-template>
            <div v-if="annonce">
                <div v-if="annonce.imgs && annonce.imgs.length <= 0">
                    <div class="bg-slate-100 p-10 rounded text-slate-500 text-center border">
                        <h2 class="text-xl font-bold">Ajouter votre pemière pièce</h2>
                        <button @click="$modal.show('modal-add-image')" class="px-4 py-2 bg-slate-400 hover:bg-slate-500 text-white rounded mt-2 text-sm duration-200"><i class="fas fa-plus mr-1"></i> Ajouter une pièce</button>
                    </div>
                </div>
                <div v-if="currentPano">
                    <!-- <h2 class="mb-2 pb-2 font-bold text-lg text-slate-600 border-b"><i class="fas fa-cog mr-1"></i> Configuration</h2> -->
                    <div class="grid grid-cols-9 gap-6">
                        <div class="col-span-6">        
                            <div>
                                <div class="relative h-[60vh]">
                                    <div v-if="startDoor" class="absolute top-[15px] left-[15px] right-[15px] bg-slate-900/80 rounded text-white p-3 z-20 text-center font-bold">
                                        <i class="fa-regular fa-hand-pointer mr-2"></i>
                                        Cliquer sur l'entrée de la nouvelle pièce
                                    </div>
                                    <!-- <button v-if="!startDoor" class="bg-white hover:bg-slate-100 duration-200 shadow-sm shadow-slate-500 rounded-full w-[40px] h-[40px] absolute bottom-[65px] right-[15px] z-10 text-[#28b67e]" style="font-size: 20px;"><i class="fa-solid fa-circle-info"></i></button>
                                    <button @click="startDoor = !startDoor" class="duration-200 shadow-sm shadow-slate-500 rounded-full w-[40px] h-[40px] absolute bottom-[15px] right-[15px] z-10" :class="startDoor ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-white hover:bg-slate-100 text-[#28b67e]'" style="font-size: 20px;">
                                        <i v-if="startDoor" class="fa-solid fa-times"></i>
                                        <i v-if="!startDoor" class="fa-solid fa-door-open"></i>
                                    </button> -->
                                    <panorama
                                    v-if="currentPano && currentPano.url_pano"
                                    height="60vh"
                                    class="rounded"
                                    :panoramaImage="currentPano.url_pano"
                                    :config="{
                                        autoLoad: true,
                                        showControls: false,
                                        hfov: 200,
                                        // minPitch: -42,
                                        // maxPitch: 42,
                                        // mouseZoom: false,
                                        keyboardZoom: false,
                                        preview: currentPano.url_cover,
                                        hotSpots: [
                                            {
                                                pitch: 14,
                                                yaw: 45,
                                                type: 'info',
                                                text: 'Information Hotspot'
                                            },
                                            {
                                                pitch: -5,
                                                yaw: 85,
                                                type: 'scene',
                                                text: 'Switch Scene',
                                            },
                                            {
                                                pitch: 3.169050350496728,
                                                yaw: 103.46380775113246,
                                                type: 'info',
                                                text: 'Test',
                                            },
                                            {
                                                pitch: 8.26971772217262,
                                                yaw: -22.886890844650313,
                                                type: 'info',
                                                text: 'Ampoule',
                                            },
                                            {
                                                pitch: -3.995628383304314,
                                                yaw: -175.4040650915669,
                                                type: 'info',
                                                text: 'Couloir',
                                            }
                                        ]
                                    }"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-span-3">
                            <!-- <div class="border rounded mb-2">
                                <div @click="openMenu('annonce')" class="duration-200 cursor-pointer py-2 px-3 flex items-center justify-between gap-2" :class="selectedMenu == 'annonce' ? 'font-bold bg-gray-200' : 'bg-gray-100 hover:bg-gray-200'">
                                    <div class="text-sm">Annonce</div>
                                    <div>
                                        <i v-if="selectedMenu != 'annonce'" class="fas fa-chevron-right"></i>
                                        <i v-if="selectedMenu == 'annonce'" class="fas fa-chevron-down"></i>
                                    </div>
                                </div>
                                <div v-if="selectedMenu == 'annonce'" class="p-3 border-t">
                                    <div class="mb-4">
                                        <label class="text-sm mb-1">Type d'annonce</label>
                                        <select v-model="annonce.type" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                                            <option :value="0">Location</option>
                                            <option :value="1">Vente immobilière</option>
                                        </select>
                                    </div>
                                    <div class="mb-4">
                                        <label class="text-sm mb-1">Adresse</label>
                                        <address-input v-model="annonce.address" />
                                    </div>
                                    <div class="mb-4">
                                        <label class="text-sm mb-1">Prix</label>
                                        <input v-model="annonce.prix" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                                    </div>
                                    <div>
                                        <label class="text-sm mb-1">Image</label>
                                        <input v-model="annonce.img" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                                    </div>
                                </div>
                            </div> -->
                            <div>
                                <div class="mb-2">
                                    <label class="text-sm mb-1">Nom de la pièce</label>
                                    <input v-model="currentPano.nom" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                                </div>
                                <!-- <div class="mb-2">
                                    <label class="text-sm mb-1">Diaporama</label>
                                    <img class="w-full rounded" :src="currentPano.url_pano" />
                                </div> -->
                                <div class="mb-2">
                                    <label class="text-sm mb-1">Vidéo</label>
                                    <div>
                                        <a class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded" :href="currentPano.url_video" target="_blank"><i class="fas fa-download mr-0.5"></i> Télécharger</a>
                                    </div>
                                </div>
                                <div class="flex items-center justify-between gap-4 mt-4">
                                    <div class="flex items-center gap-2">
                                        <button @click="startDoor = !startDoor" :class="startDoor ? 'bg-[#28b67e] text-white' : 'bg-slate-100 hover:bg-slate-200'" class="duration-200 mb-2 rounded-xl px-4 py-2 text-sm"><i class="fas fa-door-open mr-1"></i> Ajouter un accès</button>
                                        <button class="bg-slate-100 hover:bg-slate-200 duration-200 mb-2 rounded-xl px-4 py-2 text-sm"><i class="fas fa-circle-info mr-1"></i> Ajouter une bulle</button>
                                    </div>
                                </div>
                                <button @click="deletePano(currentPano)" class="mt-4 bg-red-200 hover:bg-red-300 duration-200 mb-2 rounded px-4 py-2 text-xs"><i class="fas fa-trash mr-1"></i> Supprimer l'image</button>
                            </div>

                            <!-- <div @click="img.converted ? changePano(img) : null" v-for="(img, imgIndex) in annonce.imgs" class="cursor-pointer p-2 mb-2" :class="currentPano && currentPano.id == img.id ? 'border-[#28b67e] text-[#28b67e] font-bold border-2 rounded-xl ' : (img.converted ? 'border-slate-100 hover:border-slate-200 text-slate-400 hover:text-slate-600 border-2 rounded-xl ' : 'border-b cursor-default')" :key="'img-pano-' + imgIndex"> -->

                            <!-- REMOVE -->
                            <!-- <div class="hidden">
                                <div class="mb-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded-xl shadow border p-3">
                                    <div class="text-slate-400 flex items-center gap-2 px-4">
                                        <i class="far fa-eye text-xl"></i>
                                        <div class="text-xs">Apercu</div>
                                    </div>
                                </div>
                                <div class="mb-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded-xl shadow border p-3">
                                    <div class="text-slate-400 text-center flex items-center gap-2 px-4">
                                        <i class="fas fa-pen text-xl"></i>
                                        <div class="text-xs">Modifier l'annonce</div>
                                    </div>
                                </div>
                                <div @click="$modal.show('modal-add-image')" class="mb-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded-xl shadow border p-3">
                                    <div class="text-slate-400 text-center flex items-center gap-2 px-4">
                                        <i class="fas fa-plus text-xl"></i>
                                        <div class="text-xs">Nouvelle image</div>
                                    </div>
                                </div>
                                <div class="mt-6">
                                    <div @click="img.converted ? changePano(img) : null" v-for="(img, imgIndex) in annonce.imgs" class="cursor-pointer p-2 mb-2" :class="currentPano && currentPano.id == img.id ? 'border-[#28b67e] text-[#28b67e] font-bold border-2 rounded-xl ' : (img.converted ? 'border-slate-100 hover:border-slate-200 text-slate-400 hover:text-slate-600 border-2 rounded-xl ' : 'border-b cursor-default')" :key="'img-pano-' + imgIndex">
                                        <div class="flex items-center gap-2">
                                            <div v-if="img.url_cover && img.converted" class="border w-[40px] h-[40px] relative duration-200" :style="'background-image: url(' + img.url_cover + ');'" style="background-size: cover; background-position: center center;">
                                                <div v-if="currentPano && currentPano.id == img.id" class="absolute top-0 left-0 right-0 bottom-0 bg-[#28b67e] bg-opacity-70 flex items-center justify-center"><i class="fas fa-street-view text-white text-sm"></i></div>
                                            </div>
                                            <div class="grow">
                                                <div class="text-sm">{{ img.nom }}</div>
                                                <div v-if="!img.converted">
                                                    <div class="flex gap-2">
                                                        <div class="grow w-full bg-gray-200 rounded-full h-1.5 mt-1.5">
                                                            <div class="bg-slate-400 h-1.5 rounded-full" :style="'width:' + (img.current_frame / img.nb_frames * 100).toFixed(0) + '%'"></div>
                                                        </div>
                                                        <div class="flex justify-between">
                                                            <span v-if="img.nb_frames > 0 || img.current_frame > 0" class="text-xs font-thin text-slate-800">{{(img.current_frame / img.nb_frames * 100).toFixed(0)}}%</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="img.nb_frames > 0 || img.current_frame > 0" class="text-xs font-thin text-slate-800">Conversion en cours..</div>
                                                    <div v-if="img.nb_frames <= 0 || img.current_frame <= 0" class="text-xs font-thin text-slate-800">En attente..</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <!-- REMOVE -->
                            <!-- <div class="hidden">
                                <h3 class="font-bold text-lg text-slate-800 mb-1">Annonce</h3>
                                <div class="rounded-xl border w-full bg-white p-4">
                                    <div>
                                        <div class="text-sm font-bold mb-0.5">Référence de l'annonce</div>
                                        <input type="text" class="font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                                    </div>
                                    <div class="mt-3">
                                        <div class="text-sm font-bold mb-0.5">Image de référence</div>
                                        <input type="file" class="font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                                    </div>
                                    <div class="mt-5 pt-5 border-t-2">
                                        <div class="text-sm font-bold mb-0.5">Surface</div>
                                        <input type="text" class="font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                                    </div>
                                    <div class="mt-3">
                                        <div class="text-sm font-bold mb-0.5">Description</div>
                                        <textarea type="text" class="font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" rows="4"></textarea>
                                    </div>
                                    <div class="mt-3">
                                        <div class="text-sm font-bold mb-0.5">Adresse</div>
                                        <input type="text" class="font-light text-sm p-1.5 border rounded-lg focus:outline-none w-full" />
                                    </div>
                                    <div class="mt-5 pt-5 border-t-2 text-right">
                                        <button class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded-lg text-white text-sm"><i class="fas fa-check mr-1"></i> Enregistrer</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="mt-4" v-if="annonce.imgs && annonce.imgs.length > 0">
                    <!-- <h2 class="mb-2 pb-2 font-bold text-lg text-slate-600 border-b"><i class="fas fa-compass mr-1"></i>  Navigation</h2> -->
                    <div class="flex gap-4 overflow-x-auto items-end pb-5">
                        <!-- <div class="cursor-pointer hover:bg-slate-100 duration-200 rounded flex items-center text-center w-[70px] h-[70px]">
                            <div class="text-center grow">
                                <i class="fas fa-house-circle-exclamation text-slate-600 text-sm"></i>
                                <div class="mt-2 text-xs font-light">Annonce</div>
                            </div>
                        </div> -->
                        <!-- <div class="h-[90px] w-[1px] mx-1 bg-slate-400"></div> -->
                        <div @click="$modal.show('modal-add-image')" class="cursor-pointer text-center">
                            <div class="border w-[70px] h-[70px] relative duration-200 rounded bg-slate-200 hover:bg-slate-300 duration-200 mx-auto">
                                <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center rounded"><i class="fas fa-plus text-slate-600 text-sm"></i></div>
                            </div>
                            <div class="mt-2 text-xs font-light">Nouvelle pièce</div>
                        </div>
                        <div v-for="(img, imgIndex) in annonce.imgs" :key="'img-pano-' + imgIndex">
                            <div @click="img.converted ? openMenu(img.id) : null; img.converted ? changePano(img) : null" class="duration-200 cursor-pointer text-center" :class="selectedMenu == img.id ? 'font-bold' : 'font-light'">
                                <div v-if="img.url_cover && img.converted" class="border w-[70px] h-[70px] relative duration-200 rounded  mx-auto" :style="'background-image: url(' + img.url_cover + ');'" style="background-size: cover; background-position: center center;">
                                    <div v-if="currentPano && currentPano.id == img.id" class="absolute top-0 left-0 right-0 bottom-0 bg-[#28b67e] bg-opacity-70 flex items-center justify-center rounded"><i class="fas fa-street-view text-white text-sm"></i></div>
                                </div>
                                <!-- Gauge Component -->
                                <div v-if="!img.converted" class="relative size-20 pt-6 bg-slate-100">
                                    <svg v-if="img.current_frame" class="size-full rotate-180" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                        <!-- Background Circle (Gauge) -->
                                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-gray-200" stroke-width="1" stroke-dasharray="50 100" stroke-linecap="round"></circle>

                                        <!-- Gauge Progress -->
                                        <circle cx="18" cy="18" r="16" fill="none" class="stroke-current text-blue-600" stroke-width="1.5" :stroke-dasharray="(img.current_frame / img.nb_frames * 100) / 2 + ' 100'" stroke-linecap="round"></circle>
                                    </svg>

                                    <!-- Value Text -->
                                    <div class="absolute top-10 start-1/2 transform -translate-x-1/2 text-center text-xs">
                                        <span class="font-bold text-blue-600">{{ (img.current_frame / img.nb_frames * 100).toFixed(0) + '%' }}</span>
                                    </div>
                                </div>
                                <div class="mt-2 text-xs font-light">{{ img.nom }}</div>
                                <!-- <div class="grow">
                                    <div v-if="!img.converted && img.nb_frames > 0 || img.current_frame > 0">
                                        <div class="flex gap-2">
                                            <div class="grow w-full bg-gray-200 rounded-full h-1.5 mt-1.5">
                                                <div class="bg-slate-400 h-1.5 rounded-full" :style="'width:' + (img.current_frame / img.nb_frames * 100).toFixed(0) + '%'"></div>
                                            </div>
                                            <div class="flex justify-between">
                                                <span v-if="img.nb_frames > 0 || img.current_frame > 0" class="text-xs font-thin text-slate-800">{{(img.current_frame / img.nb_frames * 100).toFixed(0)}}%</span>
                                            </div>
                                        </div>
                                        <div v-if="img.nb_frames > 0 || img.current_frame > 0" class="text-xs font-thin text-slate-800">Conversion en cours..</div>
                                        <div v-if="img.nb_frames <= 0 || img.current_frame <= 0" class="text-xs font-thin text-slate-800">En attente..</div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </admin-template>
    </div>
</template>

<script>
import Panorama from '@/components/Panorama.vue';

export default {
    components: {
        Panorama
    },
    data() {
        return {
            startDoor: false,
            currentPano: null,

            annonce: null,

            selectedMenu: '',

            videoFile: null,
            newPieceName: null,
        }
    },
    methods: {
        async getAnnonce() {
            let response = await this.$request.post('/annonce/get', {
                ida: this.$route.params.ida
            })
            this.annonce = response.data.api
            
            if (this.annonce.imgs.find((item) => item.converted == 1)) {
                this.changePano(this.annonce.imgs.find((item) => item.converted == 1))
            }

            if (this.annonce.imgs.find((item) => !item.converted)) {
                this.refreshUploadImg()
            }
        },

        async refreshUploadImg() {
            let response = await this.$request.post('/annonce/get', {
                ida: this.$route.params.ida
            })
            this.annonce.imgs = response.data.api.imgs
            if (this.annonce.imgs.find((item) => !item.converted)) {
                setTimeout(() => {
                    this.refreshUploadImg()
                }, 1500)
            }
        },

        changePano(img) {
            if (img) {
                this.currentPano = null
                this.$nextTick(() => {
                    this.currentPano = JSON.parse(JSON.stringify(img))
                })
            }
        },

        async uploadVideo(event) {
            if (event.target.files[0]) {
                this.videoFile = event.target.files[0]
            } else {
                this.videoFile = null
            }
        },

        async sendFormUploadVideo() {
            let formData = new FormData();
  		    formData.append('ida', this.$route.params.ida);
  		    formData.append('nom', this.newPieceName);
  		    formData.append('image', this.videoFile);
            
            let response = await this.$request.post('/annonce/diapo/add', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if (response.data.api == 1) {
                this.refreshUploadImg()
                this.$modal.hide('modal-add-image')
                this.newPieceName = null
            }
        },

        async deletePano(pano) {
            await this.$request.post('/annonce/diapo/delete', {
                token: pano.token
            })
            await this.refreshUploadImg()
            this.changePano(this.annonce.imgs.find((item) => item.converted == 1))
        },

        openMenu(type) {
            this.selectedMenu = this.selectedMenu == type ? null : type
        }
    },
    mounted () {
        this.getAnnonce();
    },
}
</script>

<style lang="scss" scoped>

</style>
import axios from 'axios'
import EventHub from '@/eventhub';

var myaxios = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'auth_token': localStorage.getItem('auth_token')
    },
    // baseURL: 'http://localhost:8013/api'
    baseURL: 'https://v360-api.va-net.fr/api'
})

myaxios.interceptors.response.use(function (response) {
    if (response.data.display_error) {
        EventHub.$emit('error-toast', response.data.error)
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default myaxios
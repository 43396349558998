<template>
    <div>
        <div class="relative">
            <input
            type="text"
            v-model="addressSearchInput"
            @input="searchAddress"
            class="p-1.5 border rounded w-full rounded focus:outline-none"
            :placeholder="placeholder"
            test-id="address-input"
            >
            <!-- List results -->
            <div v-if="results && results.length > 0" class="absolute w-full overflow-y-auto text-black bg-white border shadow max-h-40">
                <div @click="selectAddress(result)" v-for="(result, resultIndex) in results" :key="'address-result-' + resultIndex" :class="resultIndex < results.length -1 ? 'border-b' : ''" class="p-2 text-sm duration-100 cursor-pointer hover:bg-gray-50" :test-id="'address-search-result-' + resultIndex">
                    <i class="mr-2 fas fa-location-dot"></i> {{result.place_name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MapService from '@/services/map.service'
import AddressTool from './../tools/AddressTool'

export default {
    props: {
        placeholder: {},
        country: {}
    },

    watch: {
        country: function(value) {
            this.addressSearchInput = null
            this.results = []
        }
    },

    data() {
        return {
            addressSearchInput: "",
            results: []
        }
    },

    methods: {
        searchAddress() {
            if (this.addressSearchInput) {
                clearTimeout(this.timer)
                this.timer = setTimeout(async () => {
                    let responseAddresses = await MapService.search(this.addressSearchInput, this.country)
                    this.results = responseAddresses
                    // this.results = responseAddresses.filter((address) => address.types.includes('premise') || address.types.includes('street_address'))
                }, 600)
            } else {
                this.results = []
            }
        },

        selectAddress(result) {            
            this.addressSearchInput = result.place_name
            this.results = []
            this.$emit('input', AddressTool.convertApi(result))
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    mounted () {
        this.$eventHub.$on('error-toast', (message) => {
            this.$toast.error(message)
        })
    },
}
</script>

<style>
.text-primary {
  color: #d52d2d;
}

.bg-primary {
  background: #d52d2d;
}
</style>
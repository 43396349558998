<template>
    <div>
        <div class="fixed top-0 left-0 right-0 py-3 px-4 bg-gray-800 text-white shadow-lg shadow-slate-200">
            <div class="flex items-center gap-2 justify-between">
                <div class="flex items-center gap-2">
                    <div class="font-bold">Immo360</div>
                    <img class="h-[30px]" src="https://i.ibb.co/Pmc7SZN/Capture-d-e-cran-2024-09-28-a-10-00-25.png" alt="">
                </div>
                <input type="text" class="w-[200px] rounded bg-white/80 focus:outline-none text-slate-900 font-light px-3 py-1.5 text-sm" placeholder="Rechercher..">
            </div>
        </div>
        <div class="fixed top-14 left-0 bottom-0 w-[220px] px-4 py-4 flex flex-col justify-between">
            <div>
                <!-- <img src="https://i.ibb.co/KVpfg3s/Capture-d-e-cran-2024-09-12-a-18-48-33-removebg-preview.png" class="h-[30px] mt-4 mb-5" alt=""> -->
                <div class="bg-white rounded-lg shadow-sm shadow-slate-200 hover:shadow-none border p-1 flex items-center mb-5 hidden">
                    <i class="fas fa-magnifying-glass ml-2 mr-1.5"></i>
                    <input type="text" class="p-1 font-light text-sm focus:outline-none grow" placeholder="Rechercher une annonce.." />
                </div>
    
                <div @click="$router.push({name: 'admin.home'})" class="cursor-pointer rounded-lg p-3 flex items-center mt-2 font-semibold" style="font-size: 14px" :class="$route.name == 'admin.home' ? 'bg-gray-500 text-white' : 'hover:bg-gray-200 duration-100 text-slate-600'">
                    <i class="fas fa-home mr-4"></i> Accueil
                </div>
                <div @click="$router.push({name: 'admin.annonce.list'})" class="cursor-pointer rounded-lg p-3 flex items-center mt-2 font-semibold" style="font-size: 14px" :class="$route.name.startsWith('admin.annonce') ? 'bg-gray-500 text-white' : 'hover:bg-gray-200 duration-100 text-slate-600'">
                    <i class="fas fa-list mr-4"></i> Mes annonces
                </div>
                <div class="cursor-pointer rounded-lg p-3 flex items-center mt-2 font-semibold" style="font-size: 14px" :class="false ? 'bg-gray-500 text-white' : 'hover:bg-gray-200 duration-100 text-slate-600'">
                    <i class="far fa-building mr-4"></i> Mon agence
                </div>
                <div class="cursor-pointer rounded-lg p-3 flex items-center mt-2 font-semibold" style="font-size: 14px" :class="false ? 'bg-gray-500 text-white' : 'hover:bg-gray-200 duration-100 text-slate-600'">
                    <i class="far fa-credit-card mr-4"></i> Abonnement
                </div>
                <div class="cursor-pointer rounded-lg p-3 flex items-center mt-2 font-semibold" style="font-size: 14px" :class="false ? 'bg-gray-500 text-white' : 'hover:bg-gray-200 duration-100 text-slate-600'">
                    <i class="fas fa-headset mr-4"></i> Assistance
                </div>
            </div>

            <div @click="$router.push({name: 'logout'})" class="haffer bg-white hover:bg-gray-100 duration-200 cursor-pointer rounded-lg shadow-sm shadow-slate-300 p-2 flex items-center gap-2">
                <img src="https://static.vecteezy.com/system/resources/thumbnails/027/951/137/small_2x/stylish-spectacles-guy-3d-avatar-character-illustrations-png.png" class="h-[30px]" alt="">
                <div class="font-bold text-sm">{{ $account.prenom + ' ' + $account.nom }}</div>
            </div>
        </div>
        <div class="fixed top-14 left-0 bottom-0 left-[220px] right-0 p-6 pl-4 overflow-y-auto">
            <div class="bg-white p-6 min-h-[95vh] rounded" style="background-color: rgb(255, 255, 255); box-shadow: rgba(0, 0, 0, 0.06) 0px 4px 16px 0px;">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted () {
        if (!this.$account) {
            this.$router.push({name: 'landing'})
            return
        }
        if (!this.$account.validated) {
            localStorage.removeItem('auth_token')
            this.$router.push({name: 'landing'})
            this.$toast.error('Merci de cliquer sur le lien de validation reçu par email')
            return
        }
        if (!this.$account.agence) {
            this.$router.push({name: 'admin.agence.create'})
            return
        }
        
    },
}
</script>

<style>
.vm--overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}
</style>
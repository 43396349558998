<template>
    <div>

    </div>
</template>

<script>
export default {
    mounted () {
        console.log('ok');
        
        localStorage.removeItem('auth_token')
        window.location = '/'
    },
}
</script>

<style lang="scss" scoped>

</style>
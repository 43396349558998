<template>
  <div ref="panorama" class="panorama-container" :style="'height: ' + height"></div>
</template>

<script>
export default {
  name: 'PannellumViewer',
  props: {
    panoramaImage: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: () => ({
        autoLoad: true,
        autoRotate: -2,
        pitch: 10,
        yaw: 180,
        clickHandlerFunc: null
      })
    },
    height: {
      default: '100vh'
    }
  },
  data() {
    return {
      startClick: null
    }
  },
  mounted() {
    const clickHandlerFunc = (event, args) => {
      console.log('Panorama clicked at pitch:', event.pitch, 'yaw:', event.yaw);
      
      if (event.type === 'info') {
        alert(`Hotspot clicked with text: ${event.text}`);
      } else if (event.type === 'scene') {
        alert(`Scene hotspot clicked with ID: ${event.id}`);
      }
    };
    
    // Initialisation de Pannellum dans le hook "mounted"
    this.viewer = pannellum.viewer(this.$refs.panorama, {
      type: 'equirectangular',
      panorama: this.panoramaImage,
      mouseup: clickHandlerFunc,
      ...this.config,
    });

    // Écouter les événements de clic
    this.viewer.on('mousedown', (event) => {
      this.startClick = event
    });
    this.viewer.on('mouseup', (event) => {
      this.handleMouseDown(event);
    });

    // Écouter les clics sur les hotspots
    // this.viewer.on('click', (event) => {
    //   this.handleHotspotClick(event);
    // });
  },
  methods: {
    handlePanoramaClick(event, args) {      
      if (this.startClick.clientX == event.clientX && this.startClick.clientY == event.clientY) {
        this.$emit('new-hotspot', {
          x: event.clientX,
          y: event.clientY
        })
      }
    },
    handleMouseDown(event) {
      console.log(this.viewer.mouseEventToCoords(event));
      
    },
    handleHotspotClick(event) {
      console.log('Hotspot clicked:', event);
      if (event && event.type === 'info') {
        alert(`Hotspot clicked with text: ${event.text}`);
      }
    }
  },
  beforeDestroy() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }
};
</script>

<style scoped>
.panorama-container {
  width: 100%;
}
</style>
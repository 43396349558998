// Normalize address object
function bindObject(id, nom, prenom, address1, address2, cp, ville, longitude, latitude) {
    return {
        id: id,
        nom: nom,
        prenom: prenom,
        address1: address1,
        address2: address2,
        cp: cp,
        ville: ville,
        longitude: longitude,
        latitude: latitude,
        fullAddress: address1 + ', ' + (address2 ? address2 + ',' : '') + cp + ' ' + ville
    }
}

// Explode mapbox full address to object
function mapBoxExplodeData(value) {
    let findInfoInAddress = (keyname) => {
        let endValue = null
        value.context.forEach((item) => {
            if (item.id && item.id.split('.')[0] == keyname) {
                endValue = item.text
            }
        })
        return endValue
    }
    if (value && value.place_name) {
        return {
            addressName: value.place_name.split(',')[0],
            cp: findInfoInAddress('postcode'),
            city: findInfoInAddress('place'),
            region: findInfoInAddress('region'),
            country: findInfoInAddress('country'),
            address: value,
        }
    }
}

// Convert Mapbox Address to normal address object
function convertApi(address) {
    let addressExploded = mapBoxExplodeData(address)
    return bindObject(
        null,
        null,
        null,
        addressExploded.addressName,
        null,
        addressExploded.cp,
        addressExploded.city,
        address.center[0],
        address.center[1]
    )
}

// Convert Sav Address to normal address object
function convertSav(address) {
    return bindObject(
        address.id,
        address.nom,
        address.prenom,
        address.adresse1,
        (address.adresse2 && address.adresse2 != 'null' ? address.adresse2 : null),
        address.cp,
        address.ville,
        parseFloat(address.longitude),
        parseFloat(address.latitude)
    )
}

// Convert Sav Address list to normal address object
function convertAddressesList(addresses) {
    var newAddresses = [...addresses]
    newAddresses.forEach((address, index) => {
        newAddresses[index] = convertSav(address)
    })
    return newAddresses
}

function capitalizeTown(town) {
    if (town) {
        let words = town.split(' ')
        let townWellWritten = ''

        words.forEach((word, index) => {
            let newWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

            if (index + 1 == words.length) {
                newWord += ', '
            }
            newWord += ' '
            townWellWritten += newWord
        })
        return townWellWritten
    }
}

// Return tool functions
var AddressTool = {
    convertApi,
    convertSav,
    convertAddressesList,
    capitalizeTown
}

export default AddressTool

<template>
    <div>
        <admin-template>
            <div class="mb-4">
                <label class="text-sm mb-1">Type d'annonce</label>
                <select v-model="form.type" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
                    <option :value="0">Location</option>
                    <option :value="1">Vente immobilière</option>
                </select>
            </div>
            <div class="mb-4">
                <label class="text-sm mb-1">Adresse</label>
                <address-input v-model="form.address" />
            </div>
            <div class="mb-4">
                <label class="text-sm mb-1">Prix</label>
                <input v-model="form.prix" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
            </div>
            <div>
                <label class="text-sm mb-1">Image</label>
                <input v-model="form.img" type="text" class="p-1.5 border rounded w-full rounded text-sm focus:outline-none">
            </div>
            <button @click="sendForm" class="px-4 py-2 rounded bg-green-600 hover:bg-green-700 text-white duration-200 mt-4"><i class="fas fa-check mr-1"></i> Enregistrer</button>
        </admin-template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                type: 0,
                adresse: null,
                cp: null,
                ville: null,
                prix: null,
                img: null,
            }
        }
    },
    methods: {
        async sendForm() {
            let endData = this.form
            endData.adresse = this.form.address.address1
            endData.cp = this.form.address.cp
            endData.ville = this.form.address.ville
            endData.longitude = this.form.address.longitude
            endData.latitude = this.form.address.latitude
            let response = await this.$request.post('/annonce/edit-add', this.form)
            this.$router.push({name: 'admin.annonce.edit', params: {ida: response.data.api.ida}})
        }
    },
}
</script>

<style lang="scss" scoped>

</style>